import React from 'react';
import FormComponent from './FormComponent'; 

function App() {
    return (
        <div className="App">
            <h1>React Form Example</h1>
            <h1>life is actually pain</h1>
            <FormComponent />
        </div>
    );
}

export default App;